var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"914c62c1e465c0d56404e354d3367d30821e6301"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { AndroidService } from '@/service/Android';
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  ignoreErrors: ['Network request failed', 'Network Error'],
  release:
    process.env.VERCEL_GIT_COMMIT_SHA ||
    process.env.NEXT_PUBLLIC_VERCEL_GIT_COMMIT_SHA,
  beforeSend(event, hint) {
    const error = hint.originalException;

    const errorMessage = error?.response?.data?.error;
    try {
      if (errorMessage) {
        event.exception.values[0].type = errorMessage;
      }
    } catch (e) {}

    const statusCode = error?.response?.status;
    const url = error?.config?.url;

    if (error && (statusCode || url)) {
      event.tags = event.tags || {};
      event.tags['http.status_code'] = statusCode; // Add the status as a tag
      event.tags['http.url'] = url; // Add the url as a tag
    }
    return event;
  },
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NEXT_PUBLIC_LOCALHOST ? 0.0 : 0.4,
  environment:
    process.env.NEXT_PUBLIC_ENV !== 'development'
      ? 'production'
      : 'development',
  enabled: process.env.NEXT_PUBLIC_LOCALHOST ? false : true
  // replaysOnErrorSampleRate: 0.1,
  // replaysSessionSampleRate: 0.1,
  // integrations: [new Sentry.Replay()]
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
Sentry.setTag('sdk-version', AndroidService.getSDKVersion() || 'unknown');
Sentry.setTag('sdk-number', AndroidService.getSDKNumber() || 'unknown');
